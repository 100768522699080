import { NgModule } from '@angular/core';
import { Nl2BrPipe } from './nl2br.pipe';

@NgModule({
  declarations: [Nl2BrPipe],
  imports: [
  ],
  exports: [Nl2BrPipe]
})
export class Nl2BrPipeModule { }
